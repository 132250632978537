<template>
  <div>
    <div style="margin: 1.5em 1em">
      <div class="text-start mb-3" @click="goToHome()">
        <small style="color: white">
          <i class="fas fa-chevron-left"></i> หน้าหลัก
        </small>
      </div>

      <div class="card text-start">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h4>
                <b> ผู้ใช้ในระบบ </b>
              </h4>
            </div>
            <div class="col text-end">
              <button class="btn btn-sm btn-success" @click="showModal = true">
                <i class="fas fa-user-ninja"></i>
                เพิ่มผู้ใช้
              </button>
            </div>
          </div>

          <hr />
          <table class="table" width="100%">
            <tbody>
              <th width="10%">No.</th>
              <th>Username</th>
              <th width="3%"></th>
              <th width="3%"></th>
              <tr v-for="(user, index) in users" :key="user.id">
                <td>{{ index + 1 }}</td>
                <td>{{ user.username }}</td>
                <td>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="updatedata(user)"
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="deleteUserByObjectId(user.objectId)"
                  >
                    <i class="far fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <b class="modal-title h5">เพิ่มผู้ใช้</b>
            <button class="btn" @click="showModal = false">
              <span>X</span>
            </button>
          </div>
          <div class="modal-body text-start">
            <div class="mb-3 row">
              <b for="staticEmail" class="col-sm-2 col-form-label">
                ชื่อผู้ใช้/อีเมล <span style="color: red">*</span>
              </b>
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control"
                  id="staticEmail"
                  placeholder="กรอกชื่อผู้ใช้..."
                  v-model="username"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <b for="inputPassword" class="col-sm-2 col-form-label">
                รหัสผ่าน <span style="color: red">*</span>
              </b>
              <div class="col-sm-10">
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  id="inputPassword"
                  placeholder="กรอกรหัสผ่าน..."
                />
              </div>
            </div>
            <div class="mb-3 row">
              <b class="col-sm-2 col-form-label"> หมายเหตุ </b>
              <div class="col-sm-10">
                <textarea v-model="note" type="text" class="form-control" />
              </div>
            </div>
            <div class="mb-3 row">
              <div class="col d-grid gap-2">
                <button class="btn btn-primary" @click="savedata">
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showUpdateModal" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <b class="modal-title h5">แก้ไขข้อมูล</b>
            <button class="btn" @click="showUpdateModal = false">
              <span>X</span>
            </button>
          </div>
          <div class="modal-body text-start">
            <div class="mb-3 row">
              <b for="staticEmail" class="col-sm-2 col-form-label">
                ชื่อผู้ใช้/อีเมล <span style="color: red">*</span>
              </b>
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control"
                  id="staticEmail"
                  placeholder="กรอกชื่อผู้ใช้..."
                  v-model="editUsername"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <b for="inputPassword" class="col-sm-2 col-form-label">
                รหัสผ่าน <span style="color: red">*</span>
              </b>
              <div class="col-sm-10">
                <input
                  v-model="editPassword"
                  type="password"
                  class="form-control"
                  id="inputPassword"
                  placeholder="กรอกรหัสผ่าน..."
                />
              </div>
            </div>
            <div class="mb-3 row">
              <b class="col-sm-2 col-form-label"> หมายเหตุ </b>
              <div class="col-sm-10">
                <textarea v-model="editNote" type="text" class="form-control" />
              </div>
            </div>
            <div class="mb-3 row">
              <div class="col d-grid gap-2">
                <button class="btn btn-primary" @click="updateUser()">
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import CryptoJS from 'crypto-js'

export default {
  name: 'Users',
  data() {
    return {
      users: [],
      email: '',
      showUpdateModal: false,
      showModal: false,
      username: '',
      password: '',
      note: '',
      editObjectId: '',
      editUsername: '',
      editPassword: '',
      editNote: '',
      uid: localStorage.getItem('uid'),
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    encryptToken(provideToken) {
      const secretKey = 'sweet-pork'
      const encryptedToken = CryptoJS.AES.encrypt(
        provideToken,
        secretKey
      ).toString()
      return encryptedToken
    },
    generateToken(length) {
      const charset =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let token = ''
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length)
        token += charset[randomIndex]
      }
      return token
    },
    generateObjectId() {
      const dateString = Date.now().toString(36)
      const randomness = Math.random().toString(36).substr(2)
      return dateString + randomness
    },
    goToHome() {
      this.$router.push('/')
    },
    getUsers() {
      firebase
        .firestore()
        .collection('users')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.users.push(doc.data())
          })
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    },
    async checkUsernameExists(username, excludeObjectId = null) {
      const querySnapshot = await firebase
        .firestore()
        .collection('users')
        .where('username', '==', username)
        .get()

      if (querySnapshot.empty) {
        return false
      }

      if (excludeObjectId) {
        return !querySnapshot.docs.every(
          (doc) => doc.data().objectId === excludeObjectId
        )
      }

      return true
    },
    async savedata() {
      if (this.username == '' || this.password == '') {
        alert('กรุณากรอกข้อมูลให้ครบถ้วน')
        return
      }

      const usernameExists = await this.checkUsernameExists(this.username)
      console.log(usernameExists)
      if (usernameExists) {
        alert('Username already exists')
        return
      }

      const hash = CryptoJS.MD5(this.password).toString()

      const provideToken = this.generateToken(128)
      let data = {
        objectId: this.generateObjectId(),
        username: this.username,
        password: hash,
        note: this.note,
        provideToken: provideToken,
        token: this.encryptToken(provideToken),
        createdBy: this.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
      }

      firebase.firestore().collection('users').add(data)
      this.username = ''
      this.password = ''
      this.note = ''
      alert('บันทึกข้อมูลเรียบร้อย')
      this.showModal = false
      this.users = []
      this.getUsers()
    },
    updatedata(user) {
      this.showUpdateModal = true
      this.editUsername = user.username
      this.editNote = user.note
      this.editObjectId = user.objectId
    },
    async updateUser() {
      if (this.editUsername == '' || this.editPassword == '') {
        alert('กรุณากรอกข้อมูลให้ครบถ้วน')
        return
      }

      const usernameExists = await this.checkUsernameExists(
        this.editUsername,
        this.editObjectId
      )

      if (usernameExists) {
        alert('Username already exists')
        return
      }
      const hash = CryptoJS.MD5(this.editPassword).toString()
      const provideToken = this.generateToken(128)

      let uddata = {
        username: this.editUsername,
        password: hash,
        provideToken: provideToken,
        token: this.encryptToken(provideToken),
        note: this.editNote,
        updatedAt: new Date(),
      }
      
      firebase
        .firestore()
        .collection('users')
        .where('objectId', '==', this.editObjectId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update(uddata)
          })
        })
      alert('แก้ไขข้อมูลเรียบร้อย')

      this.editObjectId = ''
      this.editNote = ''
      this.editPassword = ''
      this.editUsername = ''
      this.users = []
      this.getUsers()

      this.showUpdateModal = false
    },
    deleteUserByObjectId(objectId) {
      if (window.confirm('คุณต้องการที่จะลบข้อมูลใช่หรือไม่ ?')) {
        firebase
          .firestore()
          .collection('users')
          .where('objectId', '==', objectId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete()
            })
            this.users = []
            this.getUsers()
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })
      }
    },
  },
}
</script>
<style scoped>
.modal-dialog {
  padding: 0.5em;
}

.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  border: 1px solid #888;
  width: 95%;
}
</style>
