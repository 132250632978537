<template>
  <div class="container">
    <div
      class="card mx-auto"
      style="border-radius: 1.5rem; width: 20rem; border: none"
    >
      <div class="m-3 text-center">
        <img src="../assets/logo.png" class="img-fluid" alt="..." width="70%" />
      </div>
      <div class="card-body text-start pt-3 pb-3">
        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping">
            <i class="fas fa-user-astronaut"></i>
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Username"
            aria-label="Username"
            aria-describedby="addon-wrapping"
            v-model="username"
          />
        </div>
        <br />
        <div class="input-group flex-nowrap">
          <span class="input-group-text" id="addon-wrapping">
            <i class="fas fa-lock"></i>
          </span>
          <input
            type="password"
            class="form-control"
            placeholder="Password"
            aria-label="Password"
            aria-describedby="addon-wrapping"
            v-model="password"
          />
        </div>
        <button
          class="mt-4 mb-4 col-12 btn btn-primary btn-block"
          @click="login"
        >
          LOGIN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import CryptoJS from 'crypto-js'

export default {
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    hashPassword(password) {
      return CryptoJS.MD5(password).toString()
    },
    verifyPassword(inputPassword, storedHash) {
      const inputHash = hashPassword(inputPassword)
      return inputHash === storedHash
    },
    login() {
      if (this.username === '' || this.password === '') {
        alert('กรุณากรอกชื่อผู้ใช้ และรหัสผ่าน')
        return
      } else {
        firebase
          .firestore()
          .collection('users')
          .where('username', '==', this.username)
          .where('password', '==', this.hashPassword(this.password))
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.exists) {
                localStorage.setItem('uid', doc.id)
                localStorage.setItem('token', doc.data().token)
                localStorage.setItem('pv', doc.data().provideToken)
                window.location = '/'
              } else {
                alert('ชื่อผู้ใช้ หรือรหัสผ่านไม่ถูกต้อง กรุณาลองอีกครั้ง')
              }
            })
          })
          .catch((error) => {
            alert('ชื่อผู้ใช้ หรือรหัสผ่านไม่ถูกต้อง กรุณาลองอีกครั้ง')
            console.log('Error getting documents: ', error)
          })
      }
    },
  },
}
</script>
<style>
.container {
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-add {
  background-color: plum;
  color: #ffff;
}

.btn-cal {
  background-color: #a74afa;
  color: #ffff;
}
</style>
