import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login'
import Users from '../views/Users.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      uid: 'vTmuXwwpVAu4D0A0yBMz',
    },
  },
]

import CryptoJS from 'crypto-js'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const uid = localStorage.getItem('uid')
  const token = localStorage.getItem('token')
  const providedToken = localStorage.getItem('pv')
  const secretKey = 'sweet-pork'

  if (to.path === '/') {
    if (!localStorage.getItem('token')) {
      next('/login')
    }
    next()
  } else if (to.path === '/login') {
    if (token && providedToken) {
      const bytes = CryptoJS.AES.decrypt(token, secretKey)
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8)
      if (providedToken === decryptedToken) {
        next('/')
      } else {
        localStorage.removeItem('token')
        localStorage.removeItem('uid')
        localStorage.removeItem('pv')
        next()
      }
    }
    next()
  } else if (uid === to.meta.uid) {
    next()
  }
})

export default router
