<template>
  <div class="p-4 mt-5">
    <div class="pb-5 text-center">
      <img src="../assets/logo.png" class="img-fluid" alt="..." width="75%" />
    </div>

    <nav class="navbar fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          style="background-image: url('./purple.jpg'); background-size: auto"
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div class="offcanvas-header">
            <img
              src="../assets/logo.png"
              class="img-fluid"
              alt="..."
              width="40%"
            />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body text-start">
            <ul
              class="navbar-nav justify-content-end flex-grow-1 pe-3"
              v-if="
                uid === 'vTmuXwwpVAu4D0A0yBMz' || uid === '0eTggoFKRS3A6qRuJ5l7'
              "
            >
              <li class="nav-item" @click="goToUser()">
                <a class="nav-link" href="#">
                  <h6 class="text-white">
                    <i class="fas fa-running p-2"></i> จัดการผู้ใช้
                  </h6>
                </a>
              </li>
            </ul>
            <ul
              class="navbar-nav justify-content-end flex-grow-1 pe-3"
              v-if="date"
            >
              <li class="nav-item">
                <a class="nav-link" @click="goHome" data-bs-dismiss="offcanvas">
                  <h6 class="text-white">
                    <i class="fas fa-home p-2"></i> หน้าหลัก
                  </h6>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="step = 2"
                  data-bs-dismiss="offcanvas"
                >
                  <h6 class="text-white">
                    <i class="fas fa-flag-checkered p-2"></i> สนาม/ก๊วน
                  </h6>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  @click="step = 3"
                  data-bs-dismiss="offcanvas"
                >
                  <h6 class="text-white">
                    <i class="fas fa-user-astronaut p-2"></i> ผู้เล่น
                  </h6>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  @click="step = 4"
                  data-bs-dismiss="offcanvas"
                >
                  <h6 class="text-white">
                    <i class="fas fa-random p-2"></i> จัดแมตซ์
                  </h6>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  @click="step = 5"
                  data-bs-dismiss="offcanvas"
                >
                  <h6 class="text-white">
                    <i class="fas fa-calculator p-2"></i> คำนวน
                  </h6>
                </a>
              </li>
              <li class="nav-item" @click="logout">
                <a class="nav-link" href="#">
                  <h6 class="text-white">
                    <i class="fas fa-sign-out-alt p-2"></i> ออกจากระบบ
                  </h6>
                </a>
              </li>
            </ul>
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3" v-else>
              <li class="nav-item" @click="logout">
                <a class="nav-link" href="#">
                  <h6 class="text-white">
                    <i class="fas fa-sign-out-alt p-2"></i> ออกจากระบบ
                  </h6>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <h6 style="color: white" class="text-center pb-3 mt-1">
      แพลตฟอร์มคำนวนค่าใช้จ่ายการเล่นแบดมินตัน
    </h6>
    <div
      class="card text-start mb-3"
      style="border-radius: 1.5rem; border: none"
      v-if="lists"
    >
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr v-for="(item, index) in data" :key="index" valign="middle">
              <th scope="row">{{ index + 1 }}</th>
              <td>
                {{ convertDate(item.datetime) }}
              </td>
              <td class="text-end">
                <button
                  class="btn btn-primary"
                  @click="getDataByObjectId(item.objectId, 5)"
                >
                  <i class="fas fa-calculator"></i>
                </button>

                <button
                  class="btn btn-primary"
                  style="
                    background-color: #ff6961;
                    border-color: #ff6961;
                    margin: 0.25em;
                  "
                  @click="deleteDataByObjectId(item.objectId)"
                >
                  <i class="far fa-trash-alt"></i>
                </button>

                <button
                  class="btn btn-secondary"
                  @click="getDataByObjectId(item.objectId, 2)"
                >
                  <i class="fas fa-angle-double-right"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      v-if="step === 1"
      class="card text-start p-1"
      style="border-radius: 1.5rem; border: none"
    >
      <div class="card-body">
        <div class="m-2">
          <label class="form-label h6"> เลือกวันที่ </label>
          <input v-model="date" type="date" class="form-control" />
          <span
            class="text-danger"
            v-if="validate != ''"
            style="font-size: 12px"
          >
            * {{ validate }}
          </span>
          <button
            class="mt-2 mb-2 col-12 btn btn-info btn-block"
            @click="selectDate"
          >
            ดำเนินการต่อ &raquo;
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="step === 2"
      class="card text-start p-1"
      style="border-radius: 1.5rem; border: none"
    >
      <div class="card-body">
        <div class="m-3">
          <h6>
            <b> <i class="far fa-calendar-alt"></i> วันที่ : </b>
            {{ convertDate(date) }}
          </h6>
          <hr />
          <label class="form-label h6 mt-2"> ระบุสนาม/ก๊วน </label>
          <input v-model="stadium" type="text" class="form-control" />
          <span
            class="text-danger"
            v-if="validate != ''"
            style="font-size: 12px"
          >
            * {{ validate }}
          </span>

          <button
            class="mt-2 mb-2 col-12 btn btn-info btn-block"
            @click="selectStadium"
          >
            ดำเนินการต่อ &raquo;
          </button>
          <button class="col-12 btn btn-secondary btn-block" @click="step = 1">
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="step === 3"
      class="card text-start p-1"
      style="border-radius: 1.5rem; border: none"
    >
      <div class="card-body">
        <div class="m-3">
          <h6>
            <b> <i class="far fa-calendar-alt"></i> วันที่ : </b>
            {{ convertDate(date) }}
          </h6>
          <h6>
            <b> <i class="fas fa-flag-checkered"></i> สนาม/ก๊วน : </b>
            {{ stadium }}
          </h6>
          <hr />
          <label class="form-label mt-2 h6"> ผู้เล่น </label>
          <ul class="list-group list-group-flush mb-3">
            <li
              class="d-flex list-group-item h6 justify-content-between p-1"
              v-for="(item, index) in player"
              :key="index"
            >
              {{ index + 1 }}. {{ item.name }}
              <div>
                <small class="text-end m-1">{{ item.courtprice }}฿</small>
                <span></span>
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="deletePlayer(item)"
                >
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </li>
          </ul>

          <b>ระบุชื่อผู้เล่นพร้อมค่าสนาม</b>
          <div class="d-flex mt-2">
            <div class="col-5">
              <input
                type="text"
                class="form-control"
                v-model="name"
                placeholder="ชื่อผู้เล่น..."
              />
            </div>
            <div class="m-1"></div>
            <div class="col-5">
              <input
                type="number"
                class="form-control ml-1"
                v-model="courtprice"
                placeholder="ค่าสนาม..."
              />
            </div>

            <button class="btn btn-light" disabled v-if="name === ''">
              <i class="fas fa-plus"></i>
            </button>
            <button
              class="btn btn-primary"
              v-else
              @click="addPlayer(name, courtprice)"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <span
            class="text-danger"
            v-if="validate != ''"
            style="font-size: 12px"
          >
            * {{ validate }}
          </span>

          <button
            class="mt-2 mb-2 col-12 btn btn-info btn-block"
            @click="selectPlayer"
          >
            ดำเนินการต่อ &raquo;
          </button>
          <button class="col-12 btn btn-secondary btn-block" @click="step = 2">
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="step === 4"
      class="card text-start p-1"
      style="border-radius: 1.5rem; border: none"
    >
      <div class="card-body">
        <div class="m-3">
          <h6>
            <b> <i class="fas fa-random"></i> จัดแมตซ์ </b>
          </h6>

          <h6 class="mt-3">
            <b> <i class="far fa-calendar-alt"></i> วันที่ : </b>
            {{ convertDate(date) }}
          </h6>
          <h6>
            <b> <i class="fas fa-flag-checkered"></i> สนาม/ก๊วน : </b>
            {{ stadium }}
          </h6>
        </div>

        <hr />
        <div class="m-3">
          <label class="form-label h6"> คอร์ท/เบอร์ลูก </label>

          <div class="d-flex">
            <input
              type="text"
              class="form-control mb-2"
              v-model="court"
              placeholder="ระบุคอร์ท/เบอร์ลูก"
            />
          </div>
          <label class="form-label h6">
            เลือกผู้เล่น <small> (ไม่เกิน 4 คน) </small>
          </label>

          <div class="d-flex">
            <select
              name=""
              id=""
              class="form-control"
              v-model="personMatch"
              @change="addPlayerInMatch(personMatch)"
              placeholder="เลือกผู้เล่นไม่เกิน 4 คน/แมตซ์"
            >
              <option
                v-for="(item, index) in player"
                :key="index"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <label class="form-label h6 mt-2"> จำนวนลูกแบด </label>

          <div class="d-flex">
            <input
              type="number"
              class="form-control mb-2"
              placeholder="ระบุจำนวนลูกแบด..."
              v-model="shuttlecock"
            />
          </div>

          <label class="form-label h6"> ราคาลูกแบด </label>

          <div class="d-flex">
            <input
              type="number"
              class="form-control"
              v-model="badPrice"
              placeholder="ระบุราคาเป็นตัวเลข..."
            />
          </div>

          <ul class="list-group list-group-flush mb-3 mt-2">
            <li
              class="d-flex justify-content-between list-group-item h6"
              v-for="(item, index) in playerInMatch"
              :key="index"
            >
              {{ index + 1 }}. {{ item.name }}
              <button
                class="btn btn-outline-danger btn-sm"
                @click="deletePlayerInMatch(item)"
              >
                <i class="fas fa-minus"></i>
              </button>
            </li>
          </ul>

          <button
            v-if="playerInMatch.length < 2"
            class="mb-2 col-12 btn btn-primary btn-block"
            disabled
          >
            ยืนยัน
          </button>

          <button
            v-else
            class="mb-2 col-12 btn btn-primary btn-block"
            @click="updatedata"
          >
            ยืนยัน
          </button>

          <button class="col-12 btn btn-info btn-block" @click="step = 5">
            รายละเอียด
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="step === 5"
      class="card text-start p-1"
      style="border-radius: 1.5rem; border: none"
    >
      <div class="card-body">
        <h6 class="mt-3">
          <b> <i class="far fa-calendar-alt"></i> วันที่ : </b>
          {{ convertDate(date) }}
        </h6>
        <h6>
          <b> <i class="fas fa-flag-checkered"></i> สนาม/ก๊วน : </b>
          {{ stadium }}
        </h6>
        <hr />

        <table class="table table-striped mt-3 text-center">
          <thead>
            <tr>
              <th scope="col">คอร์ท</th>
              <th class="text-start" scope="col">ผู้เล่น</th>
              <th scope="col">จำนวนลูกแบด</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in matches" :key="index">
              <th scope="row">{{ item.court || '' }}</th>
              <td class="text-start">
                <h6 v-for="(item, index) in item.playerInMatch" :key="index">
                  {{ item.name }}
                </h6>
              </td>
              <td>
                <h6 v-for="(badd, index) in item.shuttlecock" :key="index">
                  {{ badd }}
                </h6>
              </td>
              <td width="3%">
                <button
                  class="btn btn-info btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  @click="editPlayerInMatchData(item)"
                >
                  <i class="fas fa-pencil-alt"></i>
                </button>
                <button
                  class="btn btn-danger btn-sm mt-1"
                  @click="deleteMatches(item.id)"
                >
                  <i class="fas fa-minus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-grid gap-2">
          <button class="btn btn-info block" @click="cal">
            สรุปค่าใช้จ่าย
          </button>
        </div>
        <div class="row mt-2">
          <div class="col d-grid gap-2">
            <button class="btn btn-secondary block" @click="backMatches">
              ย้อนกลับ
            </button>
          </div>
          <div class="col d-grid gap-2">
            <button class="btn btn-primary block" @click="goHome">
              หน้าหลัก
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="step === 6"
      class="card text-start p-1"
      style="border-radius: 1.5rem; border: none"
    >
      <div class="card-body table-responsive">
        <h6 class="mt-3">
          <b> <i class="far fa-calendar-alt"></i> วันที่ : </b>
          {{ convertDate(date) }}
        </h6>
        <h6>
          <b> <i class="fas fa-flag-checkered"></i> สนาม/ก๊วน : </b>
          {{ stadium }}
        </h6>
        <hr />
        <table class="table table-striped mt-3 text-center">
          <thead>
            <tr>
              <th>ผู้เล่น</th>
              <th>เกมส์</th>
              <th>ค่าสนาม</th>
              <th>ค่าลูกแบด</th>
              <th>รวม</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in results" :key="index">
              <td>{{ index }}</td>
              <td>{{ item.games }}</td>
              <td>{{ item.courtprice }}</td>
              <td>{{ item.sum }}</td>
              <td>{{ total(item.courtprice, item.sum) }}</td>
            </tr>
            <br />
            <tr>
              <td></td>
              <td><b> รวม </b></td>
              <td>{{ stadiumPrice }}</td>
              <td>{{ summaryBadPrice() }}</td>
              <td>
                <b> {{ summaryBadPrice() + Number(stadiumPrice) }} บาท </b>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row mt-3">
          <div class="col d-grid gap-2">
            <button class="btn btn-secondary block" @click="step = 5">
              ย้อนกลับ
            </button>
          </div>
          <div class="col d-grid gap-2">
            <button class="btn btn-primary block" @click="goHome">
              หน้าหลัก
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade p-3"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-start">
            <div class="m-2">
              <label class="form-label h6"> คอร์ท/เบอร์ลูก </label>

              <div class="d-flex">
                <input
                  type="text"
                  class="form-control mb-2"
                  v-model="editCourt"
                  placeholder="ระบุคอร์ท/เบอร์ลูก"
                />
              </div>
              <label class="form-label h6"> เลือกผู้เล่น </label>

              <div class="d-flex">
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="personMatch"
                  @change="addEditPlayerInMatch(personMatch)"
                >
                  <option
                    v-for="(item, index) in player"
                    :key="index"
                    :value="item"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <!-- <button
                  class="btn btn-light"
                  disabled
                  v-if="personMatch === ''"
                >
                  <i class="fas fa-plus"></i>
                </button>
                <button
                  class="btn btn-primary"
                  v-else
                  @click="addEditPlayerInMatch(personMatch)"
                >
                  <i class="fas fa-plus"></i>
                </button> -->
              </div>

              <label class="form-label h6 mt-2"> จำนวนลูกแบด </label>

              <div class="d-flex">
                <input
                  type="number"
                  class="form-control mb-2"
                  v-model="editShuttlecock"
                  placeholder="ระบุจำนวนลูกแบด..."
                />
              </div>
              <label class="form-label h6"> ราคาลูกแบด </label>
              <div class="d-flex">
                <input
                  type="number"
                  class="form-control"
                  v-model="editBadPrice"
                  placeholder="ระบุราคาเป็นตัวเลข..."
                />
              </div>

              <ul class="list-group list-group-flush mb-3 mt-3">
                <li
                  class="d-flex justify-content-between list-group-item h6"
                  v-for="(item, index) in editPlayerInMatch"
                  :key="index"
                >
                  {{ index + 1 }}. {{ item.name }}
                  <button
                    class="btn btn-outline-danger btn-sm"
                    @click="deleteEditPlayerInMatch(item)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                </li>
              </ul>

              <button
                v-if="editPlayerInMatch.length < 2"
                class="mb-2 col-12 btn btn-primary btn-block"
                disabled
              >
                ยืนยัน
              </button>

              <button
                v-else
                class="mb-2 col-12 btn btn-primary btn-block"
                @click="updatePlayerInMatch()"
                data-bs-dismiss="modal"
              >
                ยืนยัน
              </button>
              <button
                class="mb-2 col-12 btn btn-secondary btn-block"
                data-bs-dismiss="modal"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import firebase from 'firebase'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'Home',
  data() {
    return {
      date: '',
      stadium: '',
      player: [],
      match: '',
      bad: '',
      shuttlecock: '',
      badPrice: '',
      name: '',
      courtprice: '',
      validate: '',
      step: 1,
      matches: [],
      personMatch: '',
      playerInMatch: [],
      no: 1,
      data: [],
      uid: localStorage.getItem('uid'),
      lists: false,
      court: '',
      results: '',
      stadiumPrice: '',
      editId: '',
      editCourt: '',
      editBadPrice: '',
      editPlayerInMatch: '',
      editData: '',
      editShuttlecock: '',
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    goToUser() {
      this.$router.push('/users')
    },
    backMatches() {
      this.step = 4
      this.playerInMatch = []
      this.shuttlecock = ''
      this.badPrice = ''
      this.bad = ''
      this.court = ''
    },
    total(summy, stadium) {
      let smm = summy + stadium
      return Math.round(smm)
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.clear()
          this.$router.push('/login')
        })
        .catch((error) => {
          console.log(error)
        })
    },
    editPlayerInMatchData(item) {
      this.editId = item.id
      this.editCourt = item.court || this.editCourt
      this.editShuttlecock = Number(item.shuttlecock)
      this.editBadPrice = Number(item.badPrice)
      this.editPlayerInMatch = item.playerInMatch
    },
    deleteMatches(id) {
      if (window.confirm('คุณต้องการที่จะลบข้อมูลใช่หรือไม่ ?')) {
        this.matches = this.matches.filter((subarr) => subarr.id != id)

        firebase
          .firestore()
          .collection('badminton')
          .where('objectId', '==', this.objectId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.update({
                matches: this.matches,
                updatedAt: new Date(),
              })
            })
          })
        this.mapData()
      }
    },
    deletePlayer(player) {
      let confirmtxt = `ต้องการลบผู้เล่น ${player.name} ใช่หรือไม่ ?`
      if (window.confirm(confirmtxt)) {
        this.player = this.player.filter((subarr) => subarr !== player)
      }
    },
    deletePlayerInMatch(player) {
      this.playerInMatch = this.playerInMatch.filter(
        (subarr) => subarr.name !== player.name
      )
    },
    deleteEditPlayerInMatch(player) {
      this.editPlayerInMatch = this.editPlayerInMatch.filter(
        (subarr) => subarr.name !== player.name
      )
    },
    cal() {
      this.step = 6
    },
    goHome() {
      this.$router.go(0)
    },
    generateId() {
      return Math.floor(1000 + Math.random() * 9000)
    },
    generateObjectId() {
      const dateString = Date.now().toString(36)
      const randomness = Math.random().toString(36).substr(2)
      return dateString + randomness
    },
    convertDate(datetime) {
      return moment(datetime).format('DD/MM/YYYY')
    },
    selectDate() {
      if (this.date != '') {
        this.lists = false
        this.step = 2
        this.validate = ''
      } else {
        this.validate = 'กรุณากรอกข้อมูล'
      }
    },
    selectStadium() {
      if (this.stadium != '') {
        this.step = 3
        this.validate = ''
      } else {
        this.validate = 'กรุณากรอกข้อมูล'
      }
    },
    selectPlayer() {
      if (this.player.length > 0) {
        this.step = 4
        this.validate = ''
      } else {
        this.validate = 'ระบุผู้เล่นอย่างน้อย 1 คน'
      }
    },
    addPlayerInMatch(player) {
      this.playerInMatch.push(player)
      this.playerInMatch = [...new Set(this.playerInMatch)]
      if (this.playerInMatch.length > 4) {
        alert('ไม่สามารถเลือกผู้เล่นเกิน 4 คนได้')
        this.playerInMatch.pop()
      }
      this.personMatch = ''
    },
    addEditPlayerInMatch(player) {
      this.editPlayerInMatch.push(player)
      this.editPlayerInMatch = [...new Set(this.editPlayerInMatch)]
      if (this.editPlayerInMatch.length > 4) {
        alert('ไม่สามารถเลือกผู้เล่นเกิน 4 คนได้')
        this.editPlayerInMatch.pop()
      }
    },
    addPlayer(name, courtprice) {
      let data = {
        id: this.player.length + 1,
        name: name,
        courtprice: Number(courtprice),
      }
      this.player.push(data)
      this.player = [...new Set(this.player)]
      this.name = ''
      this.courtprice = ''

      firebase
        .firestore()
        .collection('badminton')
        .where('objectId', '==', this.objectId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              player: this.player,
              updatedAt: new Date(),
            })
          })
        })
    },
    getdata() {
      firebase
        .firestore()
        .collection('badminton')
        .where('uid', '==', this.uid)
        .orderBy('createdAt', 'desc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.lists = true
            this.data.push(doc.data())
          })
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    },
    mapData() {
      let newMapped = [
        this.matches.reduce((acc, curr) => {
          curr.playerInMatch.forEach((player) => {
            if (!acc[player.name]) {
              acc[player.name] = {
                games: 0,
                sum: 0,
                courtprice: 0,
              }
            }
            acc[player.name].games += 1
            acc[player.name].courtprice = player.courtprice
            acc[player.name].sum += Math.round(curr.pricePerUse)
          })

          return acc
        }, {}),
      ]

      this.results = newMapped[0]

      this.stadiumPrice = Object.values(this.results).reduce(
        (accumulator, object) => {
          return accumulator + object.courtprice
        },
        0
      )
    },
    getDataByObjectId(objectId, step) {
      if (step !== '') {
        this.step = step
      }
      firebase
        .firestore()
        .collection('badminton')
        .where('objectId', '==', objectId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let docdata = doc.data()

            this.objectId = docdata.objectId
            this.lists = false
            this.date = docdata.datetime
            this.stadium = docdata.stadium
            this.player = docdata.player
            this.matches = docdata.matches

            this.mapData()
          })
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    },
    deleteDataByObjectId(objectId) {
      if (window.confirm('คุณต้องการที่จะลบข้อมูลใช่หรือไม่ ?')) {
        firebase
          .firestore()
          .collection('badminton')
          .where('objectId', '==', objectId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete()
            })

            this.data = []
            this.getdata()
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })
      }
    },
    summaryBadPrice() {
      return Object.values(this.results).reduce((accumulator, object) => {
        return accumulator + object.sum
      }, 0)
    },
    savedata(matches) {
      this.objectId = this.generateObjectId()

      let data = {
        uid: this.uid,
        objectId: this.objectId,
        createdAt: new Date(),
        updatedAt: new Date(),
        datetime: this.date,
        stadium: this.stadium,
        player: this.player,
        matches: matches,
      }

      let perUse = Number(this.badPrice) * Number(this.shuttlecock)
      let pricePerUse = perUse / this.playerInMatch.length

      data = {
        uid: this.uid,
        objectId: this.objectId,
        createdAt: new Date(),
        updatedAt: new Date(),
        datetime: this.date,
        stadium: this.stadium,
        player: this.player,
        matches: [
          {
            id: this.generateId(),
            court: this.court,
            playerInMatch: this.playerInMatch,
            shuttlecock: this.shuttlecock,
            badPrice: this.badPrice,
            pricePerUse: pricePerUse,
          },
        ],
      }

      firebase.firestore().collection('badminton').add(data)
      this.mapData()
    },
    updatedata() {
      let perUse = Number(this.badPrice) * Number(this.shuttlecock)
      let pricePerUse = perUse / this.playerInMatch.length

      if (!this.objectId) {
        this.savedata()
        this.matches = [
          {
            id: this.generateId(),
            playerInMatch: this.playerInMatch,
            shuttlecock: this.shuttlecock,
            badPrice: this.badPrice,
            pricePerUse: pricePerUse,
            court: this.court,
          },
        ]
      } else {
        this.matches.push({
          id: this.generateId(),
          playerInMatch: this.playerInMatch,
          shuttlecock: this.shuttlecock,
          badPrice: this.badPrice,
          pricePerUse: pricePerUse,
          court: this.court,
        })

        firebase
          .firestore()
          .collection('badminton')
          .where('objectId', '==', this.objectId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.update({
                datetime: this.date,
                stadium: this.stadium,
                matches: this.matches,
                player: this.player,
                updatedAt: new Date(),
              })
            })
          })
      }

      this.mapData()
      this.step = 5
    },
    updatePlayerInMatch() {
      let calbadprice = this.editBadPrice * this.editShuttlecock
      this.editData = {
        id: this.editId,
        court: this.editCourt,
        shuttlecock: this.editShuttlecock,
        badPrice: this.editBadPrice,
        playerInMatch: this.editPlayerInMatch,
        pricePerUse: calbadprice / this.editPlayerInMatch.length,
      }

      this.matches = this.matches.map((x) =>
        x.id === this.editId ? this.editData : x
      )

      firebase
        .firestore()
        .collection('badminton')
        .where('objectId', '==', this.objectId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              matches: this.matches,
              updatedAt: new Date(),
            })
          })
        })

      this.mapData()
    },
  },
}
</script>

<style>
.center {
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
